export default {
  wages: {
    titles: {
      wageScale: "Wage Scale",
      wageScaleUpload: "Wage Scale Upload",
      mixedCrewVesselState: "Mixed-Crew Vessel State",
      wageSettlementList: "Wage Settlement List",
      onBoardWageReport: "On Board Wage Report",
    },
    labels: {
      employer: "고용주",
      employmentType: "고용 형태",
      fleetCode: "선단 코드",
      positionCode: "직위 코드",
      salaryClass: "호봉 코드",
      regularPay: "통상급",
      overtimeAllowance: "시간외수당",
      paidTimeOff: "유급휴가비",
      jobIncentive: "직무장려수당",
      extraPay: "수당",
      vessel: "선박",
      extraPay1: "수당1",
      total: "합계",

      effectiveDate: "적용일자",
      kind: "구분",
      applicationDate: "신청일자",
      nationality: "국적",
      crewNationChange: "선원 국적 변경",
      prevMonthPayment: "전월 지급 여부",
      mixedCrewingEncouragementStartDate: "혼승장려시작일",
      mixedCrewingEncouragementEndDate: "혼승장려종료일",
      mixedCrewingEncouragementIncentive: "혼승장려금",
      remark: "비고",
      startDate: "시작일자",
      endDate: "종료일자",
      editDate: "수정일자",
      employeeNumber: "사번",
      name: "성명",
      currentDept: "현재 부서",
      korea: "한국",
      china: "중국",
      philippines: "필리핀",
      myanmar: "미얀마",
      indonesia: "인도네시아",
    },
    messages: {},
    placeholders: {},
    buttons: {
      execute: "실행",
      report: "보고서",
      transfer: "전송",
    },
    erros: {},
    validates: {},
  },
};
