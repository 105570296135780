export default {
  wages: {
    titles: {
      wageScale: "Wage Scale",
      wageScaleUpload: "Wage Scale Upload",
      mixedCrewVesselState: "Mixed-Crew Vessel State",
      wageSettlementList: "Wage Settlement List",
      onBoardWageReport: "On Board Wage Report",
    },
    labels: {
      employer: "Employer",
      employmentType: "Employment Type",
      overtimeAllowance: "Overtime Allowance",
      paidTimeOff: "Paid Time Off",
      jobIncentive: "Job Incentive",
      extraPay: "Extra pay",
      extraPay1: "Extra pay 1",
      total: "Total",
      fleetCode: "Fleet code",
      positionCode: "Position code",
      vessel: "Vessel",
      salaryClass: "Salary class",
      korea: "Korea",
      china: "China",
      philippines: "Philippines",
      myanmar: "Myanmar",
      indonesia: "Indonesia",
      regularPay: "Regular pay",
      mixedCrewingEncouragementStartDate:
        "Mixed Crewing Encouragement Start Date",
      mixedCrewingEncouragementEndDate: "Mixed Crewing Encouragement End Date",
      mixedCrewingEncouragementIncentive:
        "Mixed Crewing Encouragement Incentive",
      remark: "Remark",
      nationality: "Nationality",
      crewNationChange: "Crew Nation Change",
      prevMonthPayment: "Prev month Payment",
      kind: "Kind",
      days: "일수",
      effectiveDate: "Effective Date",
      startDate: "시작일자",
      endDate: "종료일자",
      editDate: "수정일자",
    },
    messages: {},
    placeholders: {},
    buttons: {
      execute: "Execute",
      report: "Report",
      transfer: "Transfer",
    },
    erros: {},
    validates: {},
  },
};
