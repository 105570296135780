import en from "./en";
import kr from "./kr";
import commonEn from "../common/en";
import commonKr from "../common/kr";

export const wages = { en, kr };

export const messages = {
  en: {
    ...commonEn,
    ...en,
  },
  kr: {
    ...commonKr,
    ...kr,
  },
};
