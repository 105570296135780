import { administration } from "../administration";
import { wages } from "../wages";

import { en as oldEn, kr as oldKr } from "shared";

export const messages = {
  en: {
    ...oldEn,
    ...administration.en,
    ...wages.en,
  },
  kr: {
    ...oldKr,
    ...administration.kr,
    ...wages.kr,
  },
};
