import type { LocaleMessage } from '@intlify/core-base'
import { messages } from 'locales/common'
import { isEmpty } from 'lodash'
import { CACHE, getCache, getCookie, getEnv, setCache, setCookie } from 'shared'
import { languageTypeCode } from 'shared/types'
import { KainosMessageByModule } from 'shared/utils/constants'
import { createI18n, VueI18n, VueMessageType } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { CommonService } from '@/services/commons'

interface LocaleMessages {
  [x: string]: LocaleMessage<VueMessageType>
}

const fallbackLocale: languageTypeCode = languageTypeCode.EN

const commonService = new CommonService()

export const i18n = createI18n({
  locale: getEnv({ name: 'VITE_DEFAULT_LOCALE', defaultValue: languageTypeCode.EN }),
  legacy: true,
  fallbackLocale,
  messages: getCache(CACHE.KAINOS_MESSAGES),
  warnHtmlInMessage: 'off',
})

export const globalI18n: VueI18n = i18n.global

export const setI18nLanguage = (locale: languageTypeCode) => {
  const route = useRoute()
  setCookie(CACHE.LOCALE, locale)
  if (globalI18n.locale !== locale && isEmpty(globalI18n.messages)) {
    i18n.global.locale = locale
    const locales = (route?.meta.locales as KainosMessageByModule[]) || []
    setI18nMessage(locales)
  }
}

export const setI18nMessage = async (modules: KainosMessageByModule[]) => {
  const cache: LocaleMessages = getCache(CACHE.KAINOS_MESSAGES) || {}
  let kainosMessages = cache[i18n.global.locale] || { ...messages[i18n.global.locale] }
  const params = new URLSearchParams({
    lang: i18n.global.locale,
    normalization: 'TRUE',
    ...(isEmpty(kainosMessages[KainosMessageByModule.COMMON])
      ? {
          groups: KainosMessageByModule.COMMON,
        }
      : {}),
  })
  modules.forEach((module) => {
    if (isEmpty(kainosMessages[module])) {
      params.append('groups', module)
    }
  })
  if (!params.has('groups')) {
    i18n.global.setLocaleMessage(i18n.global.locale, kainosMessages)
    return
  }
  const res = await commonService.getKainosMessage(params)
  res.data.forEach((message) => {
    kainosMessages = {
      ...kainosMessages,
      ...message,
    }
  })
  setCache(CACHE.KAINOS_MESSAGES, { [i18n.global.locale]: kainosMessages })
  i18n.global.setLocaleMessage(i18n.global.locale, kainosMessages)
}

const defaultLocale: languageTypeCode =
  getCookie(CACHE.LOCALE) ??
  (/^ko\b/.test(navigator.language)
    ? languageTypeCode.KR
    : getEnv({ name: 'VITE_DEFAULT_LOCALE', defaultValue: languageTypeCode.EN }))

if (defaultLocale) setI18nLanguage(defaultLocale)
