export default {
  administration: {
    buttons: {
      copy: "Copy",
      reset: "Reset",
    },
    labels: {
      role: "Role",
      rank: "Rank",
      name: "Name",
      position: "Position",
      roles: "Roles",
      roleAuthority: "Role Authority",
      rankAuthority: "Rank Authority",
      dutyAssign: "Duty Assign",
      userRole: "User Role",
      userList: "User List",
      roleDescription: "Role Description",
      team: "Team",
      employee: "Employee",
      assignedDate: "Assigned Date",
      assigned: "Assigned",
      retrieve: "Retrieve",
      admin: "Admin",
      copyRole: "Copy Role",
      treeLv1: "Tree (Level 1)",
      tree: "Tree",
      treeLv2: "Tree (Level 2)",
      menu: "Menu",
      menuLv3: "Menu (Level 3)",
      level2: "Level 2",
      level1: "Level 1",
      level3: "Level 3",
      description: "Description",
      icon: "Icon",
      url: "URL",
      target: "Target",
      detail: "Detail",
      use: "Use",
      shoreMenu: "Shore Menu",
      vesselMenu: "Vessel Menu",
      menuAuthority: "Menu Authority",
      title: "Title",
      manager: "Manager",
      useLeve2AsMenu: "Use Level 2 as Menu",
      addNew: "Add New",
      kor: "KOR",
      eng: "ENG",
      ownerCompany: "Owner Company",
      technicalCompany: "Technical Company",
      operatorCompany: "Operator Company",
      employeeCompany: "Employee Company",
      crewSupplierCompany: "Crew Supplier Company",
    },
    titles: {
      roles: "Roles",
      position: "Position",
      dutyAssign: "Duty Assign",
    },
    messages: {
      leave: "There is unsaved data, You sure you want to leave?",
      confirmChangeModeTitle: "You haven't saved the data yet",
      confirmChangeModeMessage: "Do you still want to leave without saving?",
      createMenuSuccess: "Create menu success",
      updateMenuSuccess: "Update menu success",
      confirmCopyRole:
        "Administrator has strong authority. Do you still want copy to?",
      resetRolesDescription: "Role Authority data is unrecoverable",
      resetRolesTitle: "Do you want to reset all roles to default setting?",
      roleExists: "This role already exists",
      selectRoleNumber: "Please select Role Number",
    },
  },
};
